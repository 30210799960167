import React from "react"
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App"

// For Bootstrap 
// import Popper from "@popperjs/core";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
